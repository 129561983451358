import React from "react"
import useIsMobile from "../../../hooks/useIsMobile"

function useAutoScrollToProceedBtn (proceedBtnRef, allInputsFilled, deps=[]) {
  const {isMobile} = useIsMobile()

  React.useEffect(() => {
    if (isMobile && allInputsFilled && proceedBtnRef?.current) {
      const proceedBtnDepth = proceedBtnRef?.current?.offsetTop || 0
      const screenHeight = document?.documentElement?.clientHeight || 0
      const topOffset = 380
      const scrollDist = proceedBtnDepth - screenHeight + topOffset
      window.scrollTo({
        top: scrollDist,
        behavior: "smooth"
      })
    }
  }, [...deps])
}

export default useAutoScrollToProceedBtn