import React from "react"
import { isBrowser } from "../utils/browserUtils"
import useIsInitialMount from "./useIsInitialMount"

function useIsMobile(defaultValue = true) {
  const isInitialMount = useIsInitialMount()
  const [isDetecting, setIsDetecting] = React.useState(true)
  const [isMobile, setIsMobile] = React.useState(defaultValue)

  function detectMobile() {
    if(isBrowser){
      setIsMobile(window.innerWidth <= 768)
      setIsDetecting(false)
    }
  }

  React.useEffect(() => {
    if (isInitialMount) {
      detectMobile()
    }
  }, [])

  React.useEffect(() => {
    isBrowser && window.addEventListener("resize", detectMobile, {passive: true})
  }, [])

  return {isMobile, isDetecting}
}

export default useIsMobile