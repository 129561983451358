import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { BackButton, Input, InputContainer, InputLabel, mandatoryField, ProgressBarComp, SelectOtherOptionsContainer } from "./common"
import { Text } from "../core/commonExports"
import { Radio } from "../core/Radio"
import { Button } from "../core/Button"
import { roofMaterialsList, wallMaterialsList } from "./sharedData"
import { getCompactTypeOfFacility } from "./sharedUtils"
import useAutoSave from "./hooks/useAutoSave"
import useDebouncedValue from "../../hooks/useDebouncedValue"
import useAutoScrollToProceedBtn from "./hooks/useAutoScrollToProceedBtn"

import Bamboo from "../../assets/images/campaigns/material-type-icons/bamboo.svg"
import BricksCementConcrete from "../../assets/images/campaigns/material-type-icons/bricksCementConcrete.svg"
// import Canvas from "../../assets/images/campaigns/material-type-icons/canvas.svg"
import Tarpaulin from "../../assets/images/campaigns/material-type-icons/tarpaulin.svg"
import Other from "../../assets/images/campaigns/business-type-icons/other.svg"

const iconMap = {
  'Bricks/Cement/Concrete': <BricksCementConcrete className="icon" />,
  'Tarpaulin': <Tarpaulin className="icon" />,
  'Bamboo': <Bamboo className="icon" />,
  // 'Canvas': <Canvas className="icon" />,
  'Tin': <StaticImage src="../../assets/images/campaigns/material-type-icons/tin.png" alt="tin" className="icon" />,
  'Plastic': <StaticImage src="../../assets/images/campaigns/material-type-icons/plastic.png" alt="plastic" className="icon" />,
  'Other': <Other className="icon" />
}

const materialsFor = {
  WALLS: 'Walls',
  ROOF: 'Roof'
}

const RoofWallMaterialsCollection = ({
  typeOfFacility,
  formProgression,
  roofMaterial,
  setRoofMaterial,
  wallMaterial,
  setWallMaterial,
  onFormSubmit,
  onGoBack,
  newTheme,
  autoSaveData,
  isAutoSaving,
}) => {
  const proceedBtnRef = React.useRef()
  const [isRoofMaterialOther, setIsRoofMaterialOther] = React.useState(false)
  const [isWallMaterialOther, setIsWallMaterialOther] = React.useState(false)

  const compactToF = getCompactTypeOfFacility(typeOfFacility)

  const debouncedRoofMaterial = useDebouncedValue(roofMaterial, 800)
  const debouncedWallMaterial = useDebouncedValue(wallMaterial, 500)

  const isSelectedWallMaterialFromOptions = wallMaterialsList.includes(wallMaterial)
  const isSelectedRoofMaterialFromOptions = roofMaterialsList.includes(roofMaterial)

  const handleMaterialTypeChange = (key, valueToUpdate) => {
		let setMaterialFn = setRoofMaterial
    let setIsOtherFn = setIsRoofMaterialOther
    let isOtherMaterial = isRoofMaterialOther
    if (key === materialsFor.WALLS) {
      setMaterialFn = setWallMaterial
      setIsOtherFn = setIsWallMaterialOther
      isOtherMaterial = isWallMaterialOther
    }

    if (valueToUpdate == 'Other (please mention)') {
			setIsOtherFn(true)
			valueToUpdate = ''
		} else if (isOtherMaterial) {
			setIsOtherFn(false)
		}
    setMaterialFn(valueToUpdate)
  }

  useAutoSave(() => {
    autoSaveData("roofMaterial", {RoofMaterial: debouncedRoofMaterial})
  }, [debouncedRoofMaterial])

  useAutoSave(() => {
    autoSaveData("wallMaterial", {WallMaterial: debouncedWallMaterial})
  }, [debouncedWallMaterial])

  const allInputsFilled = debouncedRoofMaterial && debouncedWallMaterial

  useAutoScrollToProceedBtn(
    proceedBtnRef, 
    allInputsFilled, 
    [debouncedRoofMaterial, debouncedWallMaterial]
  )
  
  const getMaterialsRadioInput = (label, icon, key, value) => {
    return (
      <Radio 
        secondary
        text={(
          <MaterialsLabelText>
            {icon}
            <Text fontSize="14px" lineHeight="16px" mfontSize="13px" mlineHeight="14px" color="rgb(153, 153, 153)" style={{margin: "0"}}>
              {/* for auto linebreaks in case of Bricks/Concrete/....... */}
              {label.replaceAll('/', ' /  ')}
            </Text>
          </MaterialsLabelText>
        )}
        value={value}
        onChange={() => handleMaterialTypeChange(key, label)}
        style={{width: "fit-content"}}
        labelStyle={{width: "fit-content", gridGap: "0.5rem", margin: "0.25rem"}}
        labelTextStyle={{fontSize: "14px", lineHeight: "16px", textAlign: "left"}}
      />
    )
  }

  const selectOtherOptions = ({isOther, isWallQuestion}) => {
    let value = roofMaterial
    let isOtherValue = isRoofMaterialOther
    let keyVal = materialsFor.ROOF
    if (isWallQuestion) {
      value = wallMaterial
      isOtherValue = isWallMaterialOther
      keyVal = materialsFor.WALLS
    }

    let radioInput = null
    if (isOther) {
      radioInput = getMaterialsRadioInput("Other (please mention)", iconMap.Other, keyVal, isOtherValue)
    } else {
      radioInput = getMaterialsRadioInput(value, iconMap?.[value], keyVal, true)
    }
    return (
      <SelectOtherOptionsContainer>
        {radioInput}
        <div role="button" onClick={() => handleMaterialTypeChange(keyVal, '')}>
          <Text fontSize="14px" mfontSize="13px" lineHeight="16px" mlineHeight="14px" fontWeight="600" color="#FF934F" style={{cursor: "pointer"}} mobileStyles={{textAlign: "right"}}>
            Change selection
          </Text>
        </div>
      </SelectOtherOptionsContainer>
    )
  }

  return (
    <form style={{display: 'flex', flexDirection: 'column', width: "100%"}} onSubmit={onFormSubmit}>
      <div style={{display: "flex", gap: "1rem", paddingRight: "1rem"}}>
        <BackButton handleClick={onGoBack} />
        <ProgressBarComp progress="70%" style={{margin: "0.75rem 0"}} />
      </div>
      <Text style={{alignSelf: "center"}} desktopStyles={{ marginBottom: "1.5rem"}} mobileStyles={{ marginBottom: "0.5rem"}} fontSize="18px" mfontSize="18px" lineHeight="22.68px" color="#8668AD" fontWeight="bold">
        Tell us about the building
      </Text>
      {formProgression}

      <InputContainer style={{marginTop: "8px"}}>
        <InputLabel htmlFor="typeOfFacility" desktopStyles={{marginBottom: "1rem"}} mobileStyles={{marginBottom: "0.75rem"}}>
          What is the Walls (दीवार) of your {compactToF} made of? {mandatoryField}
        </InputLabel>
        {isWallMaterialOther ? (
          <>
            {selectOtherOptions({isOther: true, isWallQuestion: true})}
            <Input 
              style={{marginTop: "0.5rem", textTransform: "none"}}
              placeholder="Enter the construction material here"
              value={wallMaterial}
              onChange={e => setWallMaterial(e.target.value)}
            />
          </>
        ) : isSelectedWallMaterialFromOptions ? (
          selectOtherOptions({isOther: false, isWallQuestion: true})
        ) : (
          <MaterialsContainer>
            {wallMaterialsList.map(wallMaterialItem => (
              getMaterialsRadioInput(wallMaterialItem, iconMap?.[wallMaterialItem], materialsFor.WALLS, wallMaterialItem === wallMaterial)
            ))}
            {getMaterialsRadioInput("Other (please mention)", iconMap.Other, materialsFor.WALLS, isWallMaterialOther)}
          </MaterialsContainer>
        )}
      </InputContainer>

      <InputContainer style={{marginTop: "0.5rem"}}>
        <InputLabel htmlFor="typeOfFacility" desktopStyles={{marginBottom: "1rem"}} mobileStyles={{marginBottom: "0.75rem"}}>
          What is the Roof (छत) of your {compactToF} made of? {mandatoryField}
        </InputLabel>
        {isRoofMaterialOther ? (
          <>
            {selectOtherOptions({isOther: true, isWallQuestion: false})}
            <Input 
              style={{marginTop: "0.5rem", textTransform: "none"}}
              placeholder="Enter the construction material here"
              value={roofMaterial}
              onChange={e => setRoofMaterial(e.target.value)}
            />
          </>
        ) : isSelectedRoofMaterialFromOptions ? (
          selectOtherOptions({isOther: false, isWallQuestion: false})
        ) : (
          <MaterialsContainer>
            {roofMaterialsList.map(roofMaterialItem => (
              getMaterialsRadioInput(roofMaterialItem, iconMap?.[roofMaterialItem], materialsFor.ROOF, roofMaterialItem === roofMaterial)
            ))}
            {getMaterialsRadioInput("Other (please mention)", iconMap.Other, materialsFor.ROOF, isRoofMaterialOther)}
          </MaterialsContainer>
        )}
      </InputContainer>

      <div ref={proceedBtnRef} />
      <Button
        wide
        label="Proceed"
        type={newTheme ? "flatdesign" : "secondary"}
        uppercaseLabel={false}
        onClick={onFormSubmit}
        disabled={!debouncedRoofMaterial || !debouncedWallMaterial || !wallMaterial || !roofMaterial}
        style={{alignSelf: "center"}}
      />
    </form>
  )
}

const MaterialsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.25rem;
  }
`

const MaterialsLabelText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .icon {
    flex-shrink: 0;
    width: 24px;
  }

  @media screen and (max-width: 768px) {
    .icon {
      width: 20px;
    }
  }
`

export default RoofWallMaterialsCollection